import React from 'react';
import { createRoot } from 'react-dom/client'; // React 18 以降の新しいメソッドを使用
import './index.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); // React 18のcreateRootを使ってアプリを初期化

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
