import { Auth0Provider } from "@auth0/auth0-react";
import ApiComponent from './ApiComponent';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthenticationButton from './AuthenticationButton';  // AuthenticationButtonをインポート
import AuthCallback from './AuthCallback'; // コールバック用のコンポーネント
import Profile from './Profile';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const reactUrl = `${window.location.origin}`;
const callbackPath = "/auth/callback";
const redirect_uri = `${reactUrl}${callbackPath}`;

function App() {
    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirect_uri,
                audience: audience,
            }}
        >
            <BrowserRouter>
                <div>
                    {redirect_uri}<br />
                    <AuthenticationButton />
                    <Routes>
                        <Route path={callbackPath} element={<AuthCallback />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/" element={<ApiComponent />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </Auth0Provider>
    );
}

export default App;
