import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AuthCallback = () => {
    const { handleRedirectCallback, isAuthenticated, isLoading } = useAuth0();
    const history = useNavigate();

    useEffect(() => {
        const handleAuth = async () => {
            try {
                await handleRedirectCallback();  // Auth0のリダイレクト処理を完了
            } catch (error) {
                console.error("Error handling redirect callback", error);
            }
        };
        handleAuth();
    }, [handleRedirectCallback]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            history("/profile");  // 認証が成功したらプロフィールにリダイレクト
        }
    }, [isAuthenticated, isLoading, history]);

    return <div>Loading...</div>;
};

export default AuthCallback;
