import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
const apiUrl = process.env.REACT_APP_API_URL; // 必要に応じて設定

const ApiComponent = () => {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently();
                    console.log("Access Token:", token);  // トークンを確認
                    const response = await fetch(`${apiUrl}/private`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    console.log("Response status:", response.status);
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.includes("application/json")) {
                        const responseData = await response.json();
                        setData(responseData);
                    } else {
                        console.error("Unexpected content type: ", contentType);
                    }
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            }
        };

        fetchData();
    }, [getAccessTokenSilently, isAuthenticated]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {isAuthenticated ? (
                data ? (
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                ) : (
                    <p>No data available</p>
                )
            ) : (
                <p>No data or not authenticated</p>
            )}
        </div>
    );
};

export default ApiComponent;
